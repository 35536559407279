import { getDisplayName } from 'next/dist/next-server/lib/utils';
import React from 'react';

import Breadcrumb from '../../components/enfusion/Header/Breadcrumb';
import NextNavLink from '../../components/util/NextNavLink';
import { getAbsoluteUrl } from '../../lib/helpers';

const EventHeader = ({ title, subtitle, register, eventType }) => {
    function formatDate(date) {
        let d = new Date(date);
        var month = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
        ];
        let getYear = d.getFullYear();
        let getMonth = d.getMonth();
        getMonth = month[getMonth];
        let getDay = d.getDate();
        return `${getMonth} ${getDay}, ${getYear}`;
    }
    function getDayName(date) {
        var days = [
            'Sunday',
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday'
        ];
        var d = new Date(date);
        return days[d.getDay()];
    }

    return (
        <>
            <div className="pb-16 md:pt-36 pt-28 md:pb-0">
                <div className="container">
                    <div className="flex items-center mb-5 md:mb-8 wow fadeInUp">
                        <i className="inline-block w-2.5 min-w-[0.66rem] h-2.5 mr-3 border-2 rounded-10 border-purple"></i>
                        <h2
                            className="font-medium label"
                            dangerouslySetInnerHTML={{ __html: eventType }}
                        />
                    </div>
                    {title && <h1 className="mb-8 h2 wow fadeInUp">{title}</h1>}
                    {subtitle && (
                        <div
                            dangerouslySetInnerHTML={{ __html: subtitle }}
                            className="my-5 opacity-50 text-purple-midnight h6"
                        />
                    )}
                    {/* {times &&
                        times.map((item, index) => (
                            <>
                                <div className="wow fadeInUp">
                                    <p
                                        key={index}
                                        className="my-5 opacity-50 text-purple-midnight h6"
                                    >
                                        {getDayName(item?.eventDate)},{' '}
                                        {formatDate(item?.eventDate)} |{' '}
                                        {item?.eventStartTime &&
                                            `${item?.eventStartTime} - ${item?.eventEndTime}`}{' '}
                                        {item?.timezone && item?.timezone}
                                    </p>
                                </div>
                            </>
                        ))} */}
                    {register && (
                        <a
                            href={getAbsoluteUrl(register?.url)}
                            target={register?.target}
                            className="mt-3 btn btn--primary"
                        >
                            {register?.title}
                        </a>
                    )}
                    <hr className="hidden my-16 border-t border-purple-midnight border-opacity-20 wow fadeInUp md:block" />
                </div>
            </div>
        </>
    );
};

export default EventHeader;
