import React, { useEffect, useState, useContext } from 'react';

import { EventNavigation } from './Navigation';
import FooterCTA from './FooterCTA';
import Event from './FooterCTA/event';

export default function EventFooter(props) {
    const {
        menus,
        options,
        defaults,
        optionHideCta = true,
        acf_post_event
    } = props;

    let legalMenu = [],
        languageMenu = [],
        menuItems = [];

    if (menus['legal-menu']) {
        legalMenu = menus['legal-menu'].menuItems;
    }
    if (menus['language-menu']) {
        languageMenu = menus['language-menu'].menuItems;
    }
    if (menus['main-menu']) {
        menuItems = menus['main-menu'].menuItems;
    }
    return (
        <>
            {acf_post_event?.eventSidebar && (
                <Event
                    content={defaults?.footerCtaModule?.acf_cta_module}
                    background={
                        acf_post_event?.eventCtaBackground?.mediaItemUrl
                    }
                    cta={acf_post_event?.eventLink}
                    eventType={acf_post_event?.eventType}
                />
            )}
            <div className="flex items-center justify-center my-20">
                <a
                    href="#__next"
                    className="inline-flex items-center font-medium transform -rotate-90 lead icon-circle"
                >
                    <span className="relative flex items-center h-4 ml-4">
                        <i
                            className={`inline-block en-long-arrow text-32 leading-18 relative z-[1] transition text-purple-midnight`}
                        />

                        <svg
                            width="32"
                            height="32"
                            className="absolute -right-3 -top-2 icon-circle__circle"
                        >
                            <circle
                                cx="16"
                                cy="16"
                                r="15"
                                className="icon-circle__base"
                            />
                            <circle
                                cx="16"
                                cy="16"
                                r="15"
                                style={{
                                    stroke: '#1E1A34'
                                }}
                                className="icon-circle__fill"
                            />
                        </svg>
                    </span>
                </a>
            </div>
            {optionHideCta !== true && (
                <FooterCTA
                    content={defaults?.footerCtaModule?.acf_cta_module}
                />
            )}
            <EventNavigation
                legalMenu={legalMenu}
                language={languageMenu}
                social={options.socialMediaLinks}
                options={options.mainMenuOptions.footer}
                text={acf_post_event?.eventFooterText}
            />
        </>
    );
}
